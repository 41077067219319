import { ResourceType } from '../malware';


const simpleEvent = {
    ctime: 1488180905,
    path: '/home/somelloscom/public_html/loja/in-ड्deed-smart-dos bons/admin/noerror.php',
    initiator: 'root',
    file_owner: 'john1234',
    cause: 'on-demand',
    event: 'found',
    resource_type: ResourceType.file,
};

const events = [
    'cleanup_started',
    'cleanup_pending',
    'cleanup_done',
    'unable_to_cleanup',
    'restored_original',
    'restored_from_backup',
    'added_to_ignore',
    'submitted_for_analysis',
    'restored_from_quarantine',
    'deleted_from_ignore',
    'found',
    'failed_to_cleanup',
    'failed_to_restore_original',
    'failed_to_restore_from_backup',
    'failed_to_delete',
    'failed_to_quarantine',
    'failed_to_restore_from_quarantine',
    'failed_to_ignore',
    'failed_to_delete_from_ignore',
    'requires_myimunify_protection',
];

export const responses = {
    adminSideList: {
        data: {
            max_count: 1000,
            items: [
                {
                    ctime: 1488180905,
                    path: '/domain.name/public_html/wp-content',
                    initiator: 'root',
                    cause: 'background',
                    event: 'deleted',
                    resource_type: ResourceType.db,
                    app_name: 'mysql',
                    host: 'localhost',
                    table_name: 'user3_wp_umrkc',
                    table_field: 'wp_options.options_value',
                    table_row_inf: 15,
                    port: 8888,
                    db_name: 'test',
                },
                ...(events.map(event => ({...simpleEvent, event}))),
            ],
        },
    },
    userSideList: {
        data: {
            max_count: 200,
            items: [
                {
                    ctime: 1488180905,
                    path: '/domain.name/public_html/wp-content',
                    initiator: 'root',
                    cause: 'on-demand',
                    event: 'Failed to restore: file has no clean backup version',
                },
                {
                    ctime: 223343567,
                    path: '/domain.name/public_html/wp-content',
                    initiator: 'root',
                    cause: 'on-demand',
                    event: 'Failed to restore: file has no clean backup version',
                },
                {
                    ctime: 323534567,
                    path: '/domain.name/public_html/wp-content',
                    initiator: 'root',
                    cause: 'on-demand',
                    event: 'Failed to restore: file has no clean backup version',
                },
                {
                    ctime: 423414567,
                    path: '/domain.name/public_html/wp-content',
                    initiator: 'root',
                    cause: 'on-demand',
                    event: 'Failed to restore: file has no clean backup version',
                },
                {
                    ctime: 542353567,
                    path: '/domain.name/public_html/wp-content',
                    initiator: 'root',
                    cause: 'realtime',
                    event: 'Failed to restore: file has no clean backup version',
                },
                {
                    ctime: 623245672,
                    path: '/domain.name/public_html/wp-content',
                    initiator: 'root',
                    cause: 'realtime',
                    event: 'Failed to restore: file has no clean backup version',
                },
            ],
        },
    },
};
