import { Injectable, Optional, TemplateRef } from '@angular/core';
import { map, firstValueFrom } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ComponentType } from '@angular/cdk/portal';
import { MatDialogConfig } from '@angular/material/dialog';
import {
    FileViewerComponent,
    FileViewerOptions,
} from 'app/utils/modals/file-viewer/file-viewer.component';
import { ConfirmComponent } from 'app/utils/modals/confirm/confirm.component';
import { FileService } from 'app/services/file';
import { MatDialogRef } from '@angular/material/dialog';
import { ModalEntry } from 'app/utils/modals/entry';
import { AppState } from 'app/core/app.service';
import { MdsResourceTypeMaliciousList } from '@imunify360-api/malicious';

@Injectable()
export class ModalService {
    constructor(
        private dialogService: MatDialog,
        private fileService: FileService,
        @Optional() private appState: AppState,
    ) {}
    getDialogRef<T, D>(component: ComponentType<T> | TemplateRef<D>,
                       options: MatDialogConfig<D> = {}) {
        this.addPanelClass(options, 'i360-modal-dialog-panel');
        try {
            this.addPanelClass(options, 'i360-' + (<any>component).prototype.constructor.name);
        } catch (e) {}
        // looks like there is wrong type declaration
        return this.dialogService.open(component as any, options);
    }
    dialogRefToPromise<R>(ref: MatDialogRef<any>) {
        if (this.appState && this.appState.mousePositonY) {
            if (window.innerHeight / 2 > this.appState.mousePositonY) {
                ref.updatePosition({ top: this.appState.mousePositonY + 'px'});
            } else {
                ref.updatePosition({
                    bottom: window.innerHeight - this.appState.mousePositonY + 'px',
                });
            }
        }
        return firstValueFrom<R | false>(ref.afterClosed().pipe(
            map(result => result || false),
        ));
    }
    open<R, D>(component: {new(...args): ModalEntry<D, R>} | TemplateRef<D>,
               options: MatDialogConfig<D> = {}) {
        return this.dialogRefToPromise<R>(this.getDialogRef(component, options));
    }
    async openFileViewer(params: string | FileViewerOptions): Promise<void|any> {
        try {
            let options;
            if (typeof params === 'string') {
                const path = params;
                const {chunk, limit, size} = (await firstValueFrom(this.fileService.load({path}))).data.data;
                options = {
                    data: {
                        path, chunk, limit, size,
                    },
                };
            } else {
                options = {
                    data: params,
                };
            }
            this.addPanelClass(options, 'i360-file-viewer-panel');
            return this.open(FileViewerComponent, options);
        } catch (e) { }
    }

    async openDBViewer(param: MdsResourceTypeMaliciousList): Promise<void|any> {
        try {
            const snippet = decodeURIComponent(param.snippet as string);
            const parser = new DOMParser();
            const decodedString = parser.parseFromString(snippet, 'text/html')
                .documentElement.textContent;
            const options = {
                data: {
                    path: `//${param.db_host}:${param.db_port}/${param.db_name}/${param.app_name}`,
                    chunk: decodedString || param.snippet,
                    limit: 0,
                    size: 0,
                },
            };
            this.addPanelClass(options, 'i360-file-viewer-panel');
            return this.open(FileViewerComponent, options);
        } catch (e) {}
    }

    confirm(action: string, items: string[], description?: string, descriptionArg?: {}) {
        return this.open(ConfirmComponent, {
            panelClass: ['i360-confirm-dialog-panel'],
            data: {
                action, items, description, descriptionArg
            },
        });
    }

    private addPanelClass(options: MatDialogConfig, cls: string) {
        if (typeof options.panelClass === 'string') {
            options.panelClass = [options.panelClass];
        }
        if (!options.panelClass) {
            options.panelClass = [];
        }
        if (Array.isArray(options.panelClass)) {
            options.panelClass.push(cls);
        }
    }
}
