import { AgentResponse, ListResponseData, RequestParams } from './misc';
import { ResourceType } from './malware';
import { MdsResourceTypeMaliciousList } from './malicious';

type MalwareHistoryItemInBackend = {
    ctime: number;
    path: string;
    event: string;
    initiator: string;
    cause: string;
    file_owner: string;
    id: number;
    table_name?: string | null;
    table_field?: string | null;
    table_row_inf?: number | null;
} & Omit<MdsResourceTypeMaliciousList, 'snippet'>;

export class HistoryAPI {

    static list = (params: Object) => new RequestParams(['malware', 'history', 'list'], params);
}

export class MalwareHistoryItem {
    ctime: number;
    path: string;
    event: string;
    initiator: string;
    cause: string;
    owner: string;

    // tslint:disable:variable-name
    resource_type: ResourceType;
    app_name?: string | null;
    db_host?: string | null;
    db_port?: string | null;
    db_name?: string | null;
    table_name?: string | null;
    table_field?: string | null;
    table_row_inf?: number | null;
    constructor(item: MalwareHistoryItemInBackend) {
        this.ctime = item.ctime;
        this.path = item.path;
        this.event = item.event;
        this.initiator = item.initiator;
        this.cause = item.cause;
        this.owner = item.file_owner;
        this.resource_type = item.resource_type;
        if (item.resource_type === ResourceType.db) {
            this.app_name = item.app_name;
            this.db_host = item.db_host;
            this.db_port = item.db_port;
            this.db_name = item.db_name;
            this.table_name = item.table_name || null;
            this.table_field = item.table_field || null;
            this.table_row_inf =  item.table_row_inf || null;
        }
    }
}

type MalwareHistoryListAgentResponse = AgentResponse<ListResponseData<MalwareHistoryItemInBackend>>;
