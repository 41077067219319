import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ExtendedRequestParams } from '@imunify360-api/misc';
import { XHR } from 'app/utils/xhr/xhr';
import { LicenseAPI, Product } from '@imunify360-api/license';
import { AuthState } from 'app/services/auth-state';
import { AuthAPI } from '@imunify360-api/auth';
import { ConfigRef } from 'app/core/config-ref';
import { LicenseState } from './license-state';

@Injectable()
export class LicenseService {
    Product = Product;
    register = this.xhr.request(LicenseAPI.register);
    getToken = this.xhr.rx(AuthAPI.get);

    constructor(
        public xhr: XHR,
        private router: Router,
        private authState: AuthState,
        private licenseState: LicenseState,
        public configRef: ConfigRef,
    ) {}

    get cleanupBlocked() {
        let config = this.configRef._configChange.getValue();
        if (config) {
            return this.authState.isAdmin.value
                && this.licenseState.license.value.product === this.Product.imunifyAV
                && config.PERMISSIONS.upgrade_button;
        }
        return false;
    }

    get cleanupBlockedEndUser() {
        return this.authState.isClient.value
            && this.licenseState.license.value.product === this.Product.imunifyAV;
    }

    registerViaPanel() {
        return this.xhr.post(new ExtendedRequestParams('applyLicenseFromPanel'));
    }

    checkLicense(data: {}) {
        const newLicense = this.licenseState.getLicense(data);
        if (!this.licenseState.license.value.isSame(newLicense)) {
            this.licenseState.license.next(newLicense);
        }
        if (this.authState.isClient.value) {
            return true;
        }
        const failed = this.licenseState.license.value.isFailed();
        if (failed && this.authState.isAdmin.value) {
            if (this.router.url !== '/360/admin/license' && this.router.url !== '' && this.router.url !== '/') {
                this.router.navigate(['/', IMUNIFY_PACKAGE, 'admin', 'license']);
            }
            return false;
        } else if (!failed && this.router.url === '/360/admin/license') {
            this.router.navigate(['/']);
        } else if (
            !this.licenseState.license.value.upgradeAvailable
            && this.router.url === '/AV/admin/upgrade'
        ) {
            this.router.navigate(['/']);
        }
        return true;
    }

}
